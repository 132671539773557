import { EditOutlined, LeftOutlined, MoreOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Collapse,
  Dropdown,
  Flex,
  type MenuProps,
  Pagination,
  type PaginationProps,
  Skeleton,
  Typography,
  message
} from "antd";
import type React from "react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import EmptyStateNote from "../../components/EmptyState/EmptyStateNote.component";
import GenderType from "../../components/Gender/GenderType.component";
import NotesList from "../../components/Notes/NotesList.component";
import PreWrapText from "../../components/PreWrapText/PreWrapText.component";
import ProgressSummaryView from "../../components/ProgressSummaryView/ProgressSummaryView.component";
import HowToTell from "../../components/Tutorial/HowToTell.component";
import Tutorial from "../../components/Tutorial/Tutorial.component";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { useIsMobileView, useScrollToTop } from "../../hooks/ui-hook";
import { deleteClient, getAllClients } from "../../service/client.service";
import {
  setCreateClientState,
  setLoadingNotesState,
  setNotesViewVisible,
  setSelectedClient,
  setShowDemoSignupModal
} from "../../slices/appStateSlice";
import {
  setClientForm,
  setClients,
  setIsEditing,
} from "../../slices/clientSlice";
import { setNotesDetails, setRecordingId } from "../../slices/notesSlice";
import type { TNotes } from "../../types/index.type";
import { EventType, trackEvent } from "../../utils/analytics";
import {
  formatDistanceFromInputDateTimeToNow,
  getFirstLetterOfNameAndSurname,
  sortClientsByName,
} from "../../utils/datamanipulation.utils";
import { getRandomAvatarColor } from "../../utils/ui.utils";
import Notes from "../notes/notes.component";
import styles from "./home.module.scss";
import { useRootPath } from "../../hooks/app.hook";

type TNotesSectionProps = {
  notes: TNotes[];
  setNotes: (notes: TNotes[]) => void;
  fetchNotes: (pageNumber?: number) => void;
  demoNotesRef?: React.RefObject<HTMLDivElement>;
  totalNotes?: number;
};

const NotesSection = ({
  notes,
  setNotes,
  fetchNotes,
  demoNotesRef,
  totalNotes,
}: TNotesSectionProps) => {
  const componentName = "home";
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobileView = useIsMobileView();
  const scrollToTopNode = useScrollToTop();
  const { selectedClient, currentPage } = useAppSelector(
    (state) => state.appState
  );
  const { rootPath } = useRootPath();
  const { isNotesViewVisible, loadingNotes, isDemoPath, isMobilePath } = useAppSelector(
    (state) => state.appState
  );

  const showTutorial = totalNotes === 0 && !loadingNotes && !selectedClient;

  const showPastNotesHeader = selectedClient && !isNotesViewVisible;

  const [isProgressSummaryExpanded, setIsProgressSummaryExpanded] =
    useState(false);

  const handlePageChange: PaginationProps["onChange"] = (page) => {
    if (selectedClient) {
      fetchNotes(page);
    } else {
      fetchNotes(page);
    }
  };

  const navigateToNotesDetail = (item: TNotes) => {
    const updateNotes = notes.map((note) => {
      if (note.id === item.id) {
        return { ...note, is_read: true };
      }
      return note;
    });
    setNotes(updateNotes);
    if (item.error_status !== 0 || item.status !== 1) {
      return;
    }
    trackEvent(EventType.SELECT_NOTE, { recordingId: item.id });
    dispatch(setNotesDetails(item));
    dispatch(setLoadingNotesState(true));
    dispatch(setNotesViewVisible(true));
    dispatch(setRecordingId(item.id));
    scrollToTopNode();
  };

  const clearSelection = () => {
    if (!isDemoPath) {
      dispatch(setLoadingNotesState(true));
    }
    dispatch(setSelectedClient(null));
    navigate(rootPath);
  };

  const getDiagnostic = () => {
    if (
      !selectedClient?.diagnoses ||
      (selectedClient.diagnoses && selectedClient.diagnoses.length === 0)
    )
      return "No diagnosis added";
    return selectedClient.diagnoses
      .map((d) => `${d.name} (${d.code})`)
      .join(", ");
  };

  const fetchClients = () => {
    getAllClients()
      .then((response) => {
        const sortedClients = sortClientsByName(response.data);
        dispatch(setClients(sortedClients));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleMenu = (e: any) => {
    if (e.key === "1") {
      dispatch(setCreateClientState(true));
      if (selectedClient) dispatch(setClientForm(selectedClient));
      dispatch(setIsEditing(true));
    }
    if (e.key === "2") {
      if (isDemoPath) {
        dispatch(setShowDemoSignupModal(true));
        return;
      }
      Swal.fire({
        title: "Would you like to delete this client?",
        text: "The client's notes will still be available unless you delete them.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      }).then((result) => {
        if (result.isConfirmed && selectedClient && selectedClient.id) {
          deleteClient(selectedClient.id)
            .then(() => {
              navigate(rootPath);
              fetchClients();
              dispatch(setSelectedClient(null));
              message.success("Client deleted successfully");
            })
            .catch((error) => {
              console.error(error);
              message.error("Failed to delete client. Try Again later");
            });
        }
      });
    }
  };

  const items: MenuProps["items"] = isMobileView
    ? [
        {
          key: "1",
          label: <span>Edit Client</span>,
        },
        {
          key: "2",
          label: <span>Delete Client</span>,
        },
      ]
    : [
        {
          key: "2",
          label: <span>Delete Client</span>,
        },
      ];

  const editClient = () => {
    dispatch(setCreateClientState(true));
    if (selectedClient) dispatch(setClientForm(selectedClient));
    dispatch(setIsEditing(true));
  };

  const progressSummaryLastUpdatedOn = useMemo(() => {
    if (selectedClient?.progress_summary_last_updated_at) {
      return formatDistanceFromInputDateTimeToNow(
        selectedClient.progress_summary_last_updated_at
      );
    }
    return "";
  }, [selectedClient?.progress_summary_last_updated_at]);

  const toggleSummaryViewState = () => {
    if (!isProgressSummaryExpanded) {
      trackEvent(EventType.CLICKED_PROGRESS_SUMMARY_SHOW_MORE);
    } else {
      trackEvent(EventType.CLICKED_PROGRESS_SUMMARY_SHOW_LESS);
    }
    setIsProgressSummaryExpanded((prev) => !prev);
  };

  const renderProgressSummary = () => {
    if (!selectedClient || !selectedClient.progress_summary) {
      return "Progress summary is generated after you create a note. A progress summary gives you a snapshot of your client based on past session notes. It is designed to help jog your memory and recommend next steps.";
    }

    const summary = selectedClient.progress_summary;

    return (
      <>
        {isProgressSummaryExpanded ? (
          <>
            <ProgressSummaryView summary={summary} />
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={toggleSummaryViewState}
            >
              Show Less
            </Button>
          </>
        ) : (
          <>
            <div>
              <Typography
                style={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  margin: "0.5rem 0",
                }}
              >
                Primary Concern
              </Typography>
            </div>
            <div>
              <PreWrapText text={summary.primary_concern} />
            </div>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={toggleSummaryViewState}
            >
              Show More
            </Button>
          </>
        )}
      </>
    );
  };

  if (showTutorial || isDemoPath) {
    return (<Tutorial />);
  }

  if (isNotesViewVisible) {
    return <Notes />;
  }

  return (
    <>
      {selectedClient && (
        <>
          <Button
            className={`${styles[`${componentName}-back`]} secondary-button`}
            onClick={clearSelection}
            icon={<LeftOutlined />}
          >
            Back
          </Button>

          <Flex
            vertical
            gap={10}
            className={
              styles[`${componentName}__container-left-header-client-container`]
            }
          >
            {selectedClient ? (
              <>
                <Flex gap={10} align="center" justify="space-between">
                  <Flex align="center" gap={10}>
                    <Avatar
                      style={{
                        backgroundColor: getRandomAvatarColor(
                          selectedClient.name
                        ),
                      }}
                      size={"small"}
                    >
                      {getFirstLetterOfNameAndSurname(selectedClient.name)}
                    </Avatar>
                    <Typography
                      className={
                        styles[`${componentName}__container-left-header-client`]
                      }
                    >
                      {selectedClient ? `${selectedClient.name}` : "Notes"}
                    </Typography>
                    <GenderType gender_type={selectedClient.gender_type} />
                  </Flex>
                  <Flex gap={10}>
                    {!isMobileView && (
                      <Button
                        type="primary"
                        onClick={editClient}
                        icon={<EditOutlined />}
                      >
                        Edit
                      </Button>
                    )}
                    <Dropdown
                      menu={{ items, onClick: handleMenu }}
                      trigger={["click"]}
                    >
                      <MoreOutlined style={{ color: "var(--indigo-700)" }} />
                    </Dropdown>
                  </Flex>
                </Flex>
                <Flex
                  className={
                    styles[`${componentName}__container-left-header-diagnosis`]
                  }
                  gap={5}
                >
                  <Typography
                    className={
                      styles[
                        `${componentName}__container-left-header-diagnosis-label`
                      ]
                    }
                    style={{
                      minWidth: "80px",
                    }}
                  >
                    Diagnoses (ICD-10):
                  </Typography>
                  <Typography
                    className={
                      styles[
                        `${componentName}__container-left-header-diagnosis-item`
                      ]
                    }
                  >
                    {getDiagnostic()}
                  </Typography>
                </Flex>
              </>
            ) : null}
          </Flex>
          <Collapse
            items={[
              {
                id: "special-card",
                key: "1",
                label: "Progress Summary",
                children: renderProgressSummary(),
                extra: progressSummaryLastUpdatedOn,
              },
            ]}
            defaultActiveKey={["1"]}
          />
        </>
      )}
      {loadingNotes ? (
        <>
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <div
          style={{
            overflowAnchor: "none",
          }}
        >
          {notes?.length ? (
            <>
              {showPastNotesHeader ? (
                <Flex
                  className={styles[`${componentName}__container-left-header`]}
                >
                  <Typography
                    className={
                      styles[`${componentName}__container-left-header-title`]
                    }
                  >
                    Past Notes
                  </Typography>
                </Flex>
              ) : null}
              {notes.map((item, index) => (
                <NotesList
                  noteRef={demoNotesRef}
                  item={item}
                  key={item.id}
                  index={index}
                  navigateToNotesDetail={navigateToNotesDetail}
                  fetchNotes={fetchNotes}
                />
              ))}
            </>
          ) : <>
          <EmptyStateNote />
          {!isMobilePath && <HowToTell />}
          </> }
          {notes.length ? (
            <Pagination
              onChange={handlePageChange}
              current={currentPage}
              pageSize={50}
              total={totalNotes}
              showSizeChanger={false}
              style={{ textAlign: "center" }}
            />
          ) : null}
        </div>
      )}
    </>
  );
};

export default NotesSection;
