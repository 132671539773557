import { FileAddOutlined, FileTextOutlined } from "@ant-design/icons";
import {
  Col,
  Divider,
  Drawer,
  Flex,
  Modal,
  Row,
  Tabs,
  Tour,
  TourProps,
} from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import ClientView from "../../components/ClientView/ClientView.component";
import CreateClient from "../../components/CreateClient/CreateClient.component";
import CreateNoteModal from "../../components/CreateNoteModal/CreateNoteModal.component";
import CustomiseNote from "../../components/Customise/customise-note.component";
import RecordCTA from "../../components/RecordCTA/RecordCTA.component";
import {
  demo_clients,
  demo_diagnoses_list,
  demo_notes,
} from "../../constants/data";
import { useAppSelector } from "../../hooks/redux-hooks";
import { useIsMobileView, useScrollToTop } from "../../hooks/ui-hook";
import {
  setCustomiseSectionState,
  setIsDemoPath,
  setLoadingNotesState,
  setShowDemoSignupModal,
} from "../../slices/appStateSlice";
import { setClients, setDsm } from "../../slices/clientSlice";
import { AppDispatch } from "../../store";
import { TNotes } from "../../types/index.type";
import { EventType, trackEvent } from "../../utils/analytics";
import Register from "../auth/Register";
import NotesSection from "../home/NotesSection.component";
import styles from "./demo.module.scss";

const Demo = () => {
  let chunks: Array<ArrayBuffer> = [];
  const componentName = "demo";
  const dispatch = useDispatch<AppDispatch>();
  const isMobileView = useIsMobileView();
  const ref = React.useRef(null);
  const [activeKey, setActiveKey] = React.useState("0");
  const captureRef = React.useRef(null);
  const clientViewRef = React.useRef(null);
  const clientMobileViewRef = React.useRef(null);
  const demoNotesRef = React.useRef(null);

  const {
    isCustomiseSectionVisible,
    showDemoSignupModal,
    selectedClient,
    isCreateModalVisible,
    isCreateClientDrawerVisible,
  } = useAppSelector((state) => state.appState);

  const [openTour, setOpenTour] = React.useState(false);
  const [notes, setNotes] = React.useState<TNotes[]>([]);
  const [step, setStep] = React.useState<number>(0);

  // biome-ignore lint: no need to add setIsDemoPath to the dependency array
  React.useEffect(() => {
    dispatch(setIsDemoPath(true));
    dispatch(setClients(demo_clients));
    dispatch(setDsm(demo_diagnoses_list));
    dispatch(setLoadingNotesState(false));
  }, []);

  // biome-ignore lint: no need to add scrollToTopNode to the dependency array
  React.useEffect(() => {
    if (selectedClient?.id) {
      setNotes(() =>
        demo_notes.filter((item) => item.client === selectedClient.id),
      );
    } else {
      setNotes([]);
    }
    dispatch(setLoadingNotesState(false));
  }, [selectedClient]);

  const changeStep =
    (newStep: number) => {
      if (newStep > step) {
        trackEvent(EventType.CLICKED_NEXT_STEP_TUTORIAL);
      } else {
        trackEvent(EventType.CLICKED_PREVIOUS_STEP_TUTORIAL);
      }
      setStep(newStep);
      if (!isMobileView) {
        return;
      }
      if (newStep === 1) {
        setActiveKey("1");
      } else if (newStep === 0) {
        setActiveKey("0");
      }
    };

  let steps: TourProps["steps"] = isMobileView
    ? [
        {
          title: "Generate a Clinical Note",
          description:
            "Select an option to start. Your input is automatically converted into a clinical note.",
          target: captureRef.current,
        },
        {
          title: "Manage Your Clients",
          description:
            "Each clinical note is linked to a client. Click 'Select Client', choose the desired client from the list, and click on their name to view all their past notes.",
          target: () => clientMobileViewRef.current,
        },
        {
          title: "Review Past Notes",
          description:
            "Click here to access your generated clinical note, complete with personalized session insights and an after-visit summary.",
          target: () => demoNotesRef.current,
        },
      ]
    : [
        {
          title: "Generate a Clinical Note",
          description:
            "Select 'Capture' for Nudge to listen during your session, 'Dictate' to verbally record your notes, 'Upload' to add a recording, or 'Describe' to manually type details. We will convert your input into a clinical note.",
          target: captureRef.current,
        },
        {
          title: "Manage Your Clients",
          description:
            "Each clinical note is linked to a client. Click on a client's name to view all their past notes.",
          target: () => clientViewRef.current,
        },
        {
          title: "Review Past Notes",
          description:
            "Click here to access your generated clinical note, complete with personalized session insights and an after-visit summary. You can also easily copy and paste the clinical note as needed.",
          target: () => demoNotesRef.current,
        },
      ];

  const items = [
    {
      key: "0",
      label: "Record",
      children: (
        <Col
          span={24}
          md={10}
          xl={12}
          className={styles[`${componentName}__container-right`]}
        >
          <Flex vertical align="center">
            <RecordCTA captureRef={captureRef} />
          </Flex>
        </Col>
      ),
      icon: <FileAddOutlined />,
    },
    {
      key: "1",
      label: "Notes",
      children: (
        <Col
          span={24}
          md={12}
          className={styles[`${componentName}__container-left`]}
          id="notes-container-mobile"
        >
          <NotesSection
            notes={notes}
            setNotes={setNotes}
            fetchNotes={() => {}}
            demoNotesRef={demoNotesRef}
          />
        </Col>
      ),
      icon: <FileTextOutlined />,
    },
  ];

  return (
    <>
      <Row className={styles[`${componentName}__container`]}>
        <Col
          span={24}
          md={6}
          lg={6}
          xl={6}
          className={styles[`${componentName}__container-side`]}
        >
          <Flex vertical align="center">
            <RecordCTA captureRef={captureRef} />
          </Flex>
          <Divider
            className={styles[`${componentName}__container-side-divider`]}
          />
          <Flex>
            <ClientView
              demoClients={demo_clients}
              clientViewRef={clientViewRef}
            />
          </Flex>
        </Col>
        {!isMobileView && (
          <Col
            span={24}
            md={18}
            lg={18}
            xl={18}
            className={styles[`${componentName}__container-left`]}
            id="notes-container"
          >
            <NotesSection
              notes={notes}
              setNotes={setNotes}
              fetchNotes={() => {}}
              demoNotesRef={demoNotesRef}
            />
          </Col>
        )}
        {isCreateClientDrawerVisible && <CreateClient />}
        {isCreateModalVisible && (
          <CreateNoteModal
            chunks={chunks}
            captureRecordingIdRef={ref}
            captureAudioRef={ref}
            isCaptureEnabled={false}
            resetRecordingState={() => {}}
            handleRetry={() => {}}
            initWebsocket={async () => {}}
            setRecordingTime={() => {}}
            displayRecordingTimer={() => {}}
            checkMicrophoneMuteStatus={async () => {}}
            onBufferCountChange={() => {}}
          />
        )}
      </Row>
      <Drawer
        title="Customize Note"
        open={isCustomiseSectionVisible}
        placement={isMobileView ? "bottom" : "right"}
        height={isMobileView ? "85%" : "100%"}
        closable={true}
        destroyOnClose
        onClose={() => dispatch(setCustomiseSectionState(false))}
      >
        <Flex
          vertical
          justify="space-between"
          style={{
            height: "100%",
          }}
        >
          <Flex
            className={`${styles[`${componentName}__customise_clearance`]}`}
          >
            <span></span>
          </Flex>
          <CustomiseNote noPadding={true} />
        </Flex>
      </Drawer>
      {isMobileView && (
        <Tabs
          tabPosition={"bottom"}
          size="large"
          activeKey={activeKey}
          onChange={(key) => setActiveKey(key)}
          items={items}
          className={`${styles[`${componentName}__mobile_view_tabs`]}`}
          centered
        />
      )}
      <Modal
        open={showDemoSignupModal}
        footer={null}
        className={`${styles[`${componentName}__signup_modal`]}`}
        onCancel={() => {
          dispatch(setShowDemoSignupModal(false));
        }}
      >
        <Register singleGrid={true} />
      </Modal>
      <Tour
        open={openTour}
        onClose={() => setOpenTour(false)}
        steps={steps}
        onChange={changeStep}
        disabledInteraction
      />
    </>
  );
};

export default Demo;
